function InputBox({groceries, setGroceries, handleSubmit}) {
  return (
    <>
    <div className="input-section">
        <form className="form" onSubmit={handleSubmit}>
            <textarea className="input-box"
            type="text" 
            // autoFocus="true"
            placeholder="Paste your grocery list here..."
            value={groceries}
            onChange={(e) => setGroceries(e.target.value)}
            />
            <input className="submit" type="submit" />
        </form>
    </div>
    </>
  )
}

export default InputBox;