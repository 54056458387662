import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import XIcon from '@mui/icons-material/X';
import { useMediaQuery } from '@mui/material';

export function ActionButtons({ textToCopy, handleAddCategory }) {
    return (
        <Box display="flex" justifyContent="center" mb={2}>
            <Tooltip title="Copy to clipboard">
                <Button
                    color="primary"
                    onClick={() => {
                        navigator.clipboard.writeText(textToCopy);
                    }}
                    style={{ marginRight: 8, minWidth: 40, minHeight: 40 }}
                >
                    <CopyAllIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Send via email">
                <Button
                    color="primary"
                    onClick={() => {
                        const subject = encodeURIComponent('Sorted Groceries');
                        const body = encodeURIComponent(textToCopy);
                        window.href = `mailto:?subject=${subject}&body=${body}`;
                    }}
                    style={{ marginRight: 8, minWidth: 40, minHeight: 40 }}
                >
                    <EmailIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Share on Twitter">
                <Button
                    color="primary"
                    onClick={() => {
                        const text = encodeURIComponent("I automatically sorted my groceries using this tool. Check it out!");
                        const url = encodeURIComponent(window.location.href);
                        const hashtags = encodeURIComponent('grocerysorter');
                        window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`, '_blank');
                    }}
                    style={{ marginRight: 8, minWidth: 40, minHeight: 40 }}
                >
                    <XIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Add new category">
                <Button
                    color="primary"
                    onClick={handleAddCategory}
                    style={{ marginRight: 8, minWidth: 40, minHeight: 40 }}
                >
                    <AddIcon />
                </Button>
            </Tooltip>
        </Box>
    );
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isChecked) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "white",
    color: isChecked ? "gray" : "black",
    textDecoration: isChecked ? "line-through" : "none",
    textDecorationColor: isChecked ? "gray" : "none",
    textDecorationThickness: isChecked ? "2px" : "none",
    animation: isChecked ? "strikeThrough 1s forwards" : "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 300,
    margin: '1px 10px'
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

function DraggableItem({ item, index, onDelete, onCheck }) {
    return (
        <Draggable
            key={item.id}
            draggableId={item.id}
            index={index}
        >
            {(provided, snapshot) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.checked), padding: '3px' }}
                >
                    <CardContent style={{ display: 'flex', alignItems: 'center', padding: '3px' }}>
                        <Checkbox
                            checked={item.checked}
                            onChange={() => onCheck(item.id)}
                        />
                        <Typography variant="body2" component="p" style={{ textAlign: 'left' }}>
                            {item.content}
                        </Typography>
                        <Button
                            color="secondary"
                            onClick={onDelete}
                            style={{ marginLeft: 'auto' }}
                        >
                            <CloseIcon className='delete' />
                        </Button>
                    </CardContent>
                </Card>
            )}
        </Draggable>
    );
}

function DroppableList({ items = [], droppableId, category, onDelete, onEditCategory, onCheck }) {
    const [isEditing, setIsEditing] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState(category);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCategoryNameChange = (e) => {
        setNewCategoryName(e.target.value);
    };

    const handleCategoryNameBlur = () => {
        setIsEditing(false);
        onEditCategory(droppableId, newCategoryName);
    };

    return (
        <Droppable key={droppableId} droppableId={droppableId}>
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                >
                    <Box display="flex" alignItems="center" mb={2} justifyContent="space-between">
                        {isEditing ? (
                            <TextField
                                value={newCategoryName}
                                onChange={handleCategoryNameChange}
                                onBlur={handleCategoryNameBlur}
                                autoFocus
                            />
                        ) : (
                            <Typography variant="h6">{category}</Typography>
                        )}
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={isEditing ? handleCategoryNameBlur : handleEditClick}>
                                {isEditing ? <CheckIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton>
                                <DragIndicatorIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {items.map((item, index) => (
                        <DraggableItem
                            key={item.id}
                            item={item}
                            index={index}
                            onDelete={() => onDelete(droppableId, index)}
                            onCheck={onCheck}
                        />
                    ))}
                    {provided.placeholder}
                </Box>
            )}
        </Droppable>
    );
}

function Result({ groceries }) {
    const [state, setState] = useState([]);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        setState(Object.entries(groceries).map(([category, items]) => ({
            category,
            items: items.map((item, index) => ({ id: `${category}-${index}`, content: item, checked: false }))
        })));
    }, [groceries]);

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (result.type === 'CATEGORY') {
            const newState = reorder(state, source.index, destination.index);
            setState(newState);
        } else {
            const sourceIndex = +source.droppableId;
            const destinationIndex = +destination.droppableId;

            if (sourceIndex === destinationIndex) {
                const items = reorder(state[sourceIndex].items, source.index, destination.index);
                const newState = [...state];
                newState[sourceIndex].items = items;
                setState(newState);
            } else {
                const result = move(state[sourceIndex].items, state[destinationIndex].items, source, destination);
                const newState = [...state];
                newState[sourceIndex].items = result[sourceIndex];
                newState[destinationIndex].items = result[destinationIndex];
                setState(newState.filter(group => group.items.length));
            }
        }
    };

    const handleDelete = (droppableId, index) => {
        const newState = [...state];
        newState[droppableId].items.splice(index, 1);
        setState(newState.filter(group => group.items.length));
    };

    const handleAddCategory = () => {
        const newCategory = { category: `New Category ${state.length + 1}`, items: [] };
        setState([...state, newCategory]);
    };

    const handleEditCategory = (droppableId, newCategoryName) => {
        const newState = [...state];
        newState[droppableId].category = newCategoryName;
        setState(newState);
    };

    const handleCheck = (itemId) => {
        const newState = state.map(group => ({
            ...group,
            items: group.items.map(item => item.id === itemId ? { ...item, checked: !item.checked } : item)
        }));
        setState(newState);
    };

    if (state.length) {
        const textToCopy = state.map(group => {
            return `${group.category}:\n${group.items.map(item => `  ${item.content}`).join('\n')}`;
        }).join('\n\n');
        return (
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} p={2} className="bottom" style={{ minHeight: '100vh' }}>
                {isMobile ? (
                    <>
                        <Box width="100%" p={2} className="top-ad">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-6258112320145501"
                                data-ad-slot="f08c47fec0942fa0"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            />
                        </Box>
                        <Box flexGrow={1} p={2} display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="h4" gutterBottom style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 48, color: "#899E3C" }}>
                                Sorted Groceries
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{ fontFamily: 'Inter', color: "#4D4D4D" }}>
                                Here are your groceries sorted into categories. 
                            </Typography>
                            <ActionButtons textToCopy={textToCopy} handleAddCategory={handleAddCategory} />
                            <Box display="flex" justifyContent="center" className="dragdropzone" width="100%">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="all-categories" type="CATEGORY">
                                        {(provided) => (
                                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                                                {state.map((group, index) => (
                                                    <Draggable key={index} draggableId={`category-${index}`} index={index}>
                                                        {(provided) => (
                                                            <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <DroppableList
                                                                    items={group.items}
                                                                    droppableId={`${index}`}
                                                                    category={group.category}
                                                                    onDelete={handleDelete}
                                                                    onEditCategory={handleEditCategory}
                                                                    onCheck={handleCheck}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        </Box>
                        <Box width="100%" p={2} className="bottom-ad">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-6258112320145501"
                                data-ad-slot="f08c47fec0942fa0"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box width="15%" p={2} className="left-sidebar">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-6258112320145501"
                                data-ad-slot="f08c47fec0942fa0"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            />
                        </Box>
                        <Box flexGrow={1} p={2} display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="h4" gutterBottom style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 48, color: "#899E3C" }}>
                                Sorted Groceries
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{ fontFamily: 'Inter', color: "#4D4D4D" }}>
                                Here are your groceries sorted into categories. 
                            </Typography>
                            <ActionButtons textToCopy={textToCopy} handleAddCategory={handleAddCategory} />
                            <Box display="flex" justifyContent="center" className="dragdropzone" width="100%">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="all-categories" type="CATEGORY">
                                        {(provided) => (
                                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                                                {state.map((group, index) => (
                                                    <Draggable key={index} draggableId={`category-${index}`} index={index}>
                                                        {(provided) => (
                                                            <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <DroppableList
                                                                    items={group.items}
                                                                    droppableId={`${index}`}
                                                                    category={group.category}
                                                                    onDelete={handleDelete}
                                                                    onEditCategory={handleEditCategory}
                                                                    onCheck={handleCheck}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        </Box>
                        <Box width="15%" p={2} className="right-sidebar">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-6258112320145501"
                                data-ad-slot="f08c47fec0942fa0"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    }
    return null;
}

export default Result;