import './App.css';
import "@fontsource/inter";

import { InputBox, Result } from './components';
import React, { useRef, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { APIService } from "./api";
import cart from './images/cart.jpg';

const theme = createTheme();

function App() {
  const [groceries, setGroceries] = useState("");
  const [normalizedGroceries, setNormalizedGroceries] = useState([]);
  const [sortedGroceries, setSortedGroceries] = useState({});
  let scrollRef = useRef(null);

  const classifyGroceries = (normalized) => {
    APIService.ClassifyGroceries(normalized)
      .then((response) => {
        setSortedGroceries(response);
        console.log("got from server:", response);
      })
      .catch(error => console.log('error', error));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const normalized = groceries.split(/[\n,-]+/).map(item => item.trim()).filter(item => item);
    setNormalizedGroceries(normalized);

    console.log("sending to server:", normalized);
    classifyGroceries(normalized);
    setTimeout(() => {
      if (scrollRef.current) {
        window.scrollTo({ behavior: 'smooth', top: scrollRef.current.clientHeight, left: 0 });
      }
    }, 500);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="splitScreen" ref={scrollRef}>
          <div className="left">
            <div className="title">
              Welcome to
            </div>
            <div className="subtitle">
              Grocery Sorter!
            </div>
            <div className="paragraph">
              Paste in a list of grocery items and it will be sorted into categories.
            </div>
            <InputBox groceries={groceries} setGroceries={setGroceries} handleSubmit={handleSubmit} />
          </div>
          <div className="right">
            <img src={cart} className="logo" alt="logo" />
          </div>
        </div>
        <Result className="result" id="result" groceries={sortedGroceries} />
      </div>
    </ThemeProvider>
  );
}

export default App;
